.App {
  width: 300px;
  margin: 50px auto;
  border: 1px solid #eee;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.display {
  height: 50px;
  margin-bottom: 20px;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;
  border-radius: 5px;
}

.display-jp {
  height: 50px;
  margin-bottom: 20px;
  background-color: #7e7e7e;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;
  border-radius: 5px;
  color: #fff;
}

.buttons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 10px;
}

button {
  padding: 20px;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 18px;
}

button:active {
  background-color: darkorange;
}

button[data-number] {
  background-color: lightblue;
}

button[data-operator] {
  background-color: orange;
}

button[data-control] {
  background-color: red;
}
